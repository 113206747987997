import type {
    CartPageMicroFrontendInterface
} from '~/composables/types/service/cartPageMicroFrontendInterface';

export const ccMicroFrontend = (): CartPageMicroFrontendInterface => {
    const { $cookies } = useNuxtApp();

    const getAccessToken = (): string => $cookies.getRefs(CookieNameTypes.TOKEN_KEY)?.replace('Bearer ', '') ?? '';

    return {
        getAccessToken,
    };
};
